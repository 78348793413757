<template>
  <auth-form-wrapper
    :title="$t('auth.login.title')"
    :subtitle="$t('auth.login.subtitle')"
    :error="error"
  >
    <b-form 
      class="auth-form mt-2" 
      @submit.prevent="handleSubmit"
      autocomplete="off"
    >
      <!-- Email -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.login.form.email.label') }}
        </label>
        <b-form-input
          id="login-email"
          v-model="formData.email"
          type="email"
          name="email"
          :placeholder="$t('auth.login.form.email.placeholder')"
          required
        />
      </div>

      <!-- Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.login.form.password.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="login-password"
            v-model="formData.password"
            :type="passwordFieldType"
            name="password"
            :placeholder="$t('auth.login.form.password.placeholder')"
            class="form-control-merge"
            required
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Forgot Password Link -->
      <div class="mb-1">
        <span>{{ $t('auth.login.forgot-password.message') }}</span>
        <b-link
          class="auth-link"
          :to="forgotPasswordRoute"
        >
          {{ $t('auth.login.forgot-password.action') }}
        </b-link>
      </div>

      <!-- Submit Button -->
      <div class="auth-btn-container d-flex justify-content-center">
        <b-button
          type="submit"
          variant="primary"
          class="auth-btn auth-btn-primary mt-2 mb-2"
          :disabled="invalid"
        >
          {{ $t('auth.login.form.action.sign-in') }}
        </b-button>
      </div>
    </b-form>

    <!-- Sign Up Link -->
    <p v-if="showSignup" class="text-center mt-2">
      <span>{{ $t('auth.login.sign-up.message') }}</span>
      <b-link :to="signupRoute" class="auth-link">
        {{ $t('auth.login.sign-up.action') }}
      </b-link>
    </p>

    <!-- SSO Buttons -->
    <div
      v-if="showOAuthButtons"
      class="text-center mt-2"
    >
      <p>· {{ $t("auth.login.form.action.sign-in-with") }} ·</p>
      
      <!-- Google Auth -->
      <b-button
        v-if="showGoogleButton"
        variant="outline-primary"
        :href="community.googleURL"
        :class="showSSObutton ? 'mr-3' : ''"
      >
        <b-icon-google />
      </b-button>

      <!-- Linkedin Auth -->

      <!-- SSO Auth -->
      <b-button
        v-if="showSSObutton"
        variant="outline-primary"
        :href="ssoURL"
      >
        SSO
      </b-button>

      <!-- Nectios Auth -->
    </div>
  </auth-form-wrapper>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { translateTranslationTable } from '@/@core/libs/i18n/utils'
import { getImageResource } from '@/@core/utils/image-utils'
import { getDomain } from '@/store/auth/auth.store'
import { AccessTokenKey } from '@/store/auth/auth.store.constants'
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg'
import Service from '@/config/service-identifiers'
import Vue from 'vue'
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'

export default {
  name: 'Login',

  inject: ['authApiUrl'],

  mixins: [togglePasswordVisibility],

  components: {
    AuthFormWrapper
  },

  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      error: null,
      invalid: false,
      community: null,
      oAuthApps: null,
      ssoURL: null,
      isLoading: true
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.collective;
    },
    communitySlug() {
      const slugParam = this.$route.params.communityId;
      // Validate slug - if it's undefined or invalid, return an empty string instead
      if (!slugParam || slugParam === 'undefined' || slugParam === 'null') {
        // console.log('Invalid community slug detected, using empty string');
        return '';
      }
      return slugParam;
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    isIntercontinentalAlliance() {
      return this.community?.key === '6725a490-2547-11ee-a8ab-dfc8db3ddec2';
    },
    // Add computed properties for route names
    forgotPasswordRoute() {
      return { name: 'auth-forgot', params: { communityId: this.communitySlug } }
    },
    signupRoute() {
      return { name: 'auth-signup', params: { communityId: this.communitySlug } }
    },
    showSignup() {
      return this.community && this.community.accessibility < 2;
    },
    showOAuthButtons() {
      return this.communitySlug === 'nectios-user-guide';
      //TODO: for the moment we are not using google auth

      return (this.community && 
              this.community.googleURL && 
              this.oAuthApps && 
              this.oAuthApps.apps && 
              this.oAuthApps.apps['92']) || 
              this.communitySlug === 'barcelona-activa'
    },
    showGoogleButton() {
      return this.community && 
             this.community.googleURL && 
             this.oAuthApps && 
             this.oAuthApps.apps && 
             this.oAuthApps.apps['92']
    },
    showSSObutton() {
      return false;
    },
    backgroundStyle() {
      if (!this.community || 
          !this.community.customization || 
          !this.community.customization.theme || 
          !this.community.customization.theme.login || 
          !this.community.customization.theme.login.backgrounds || 
          !this.community.customization.theme.login.backgrounds.length) {
        return null;
      }

      return {
        backgroundImage: `url(${getImageResource(this.community.customization.theme.login.backgrounds[0])})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat !important',
      };
    },
    communityLogo() {
      if (!this.community) return null;
      
      const logo = this.community.customization && 
                   this.community.customization.theme && 
                   this.community.customization.theme.login && 
                   this.community.customization.theme.login.logo;
      
      const logoURL = logo || 
                     (this.community.logoURL) || 
                     (this.community.header && this.community.header.logo_url);
      
      return logoURL ? getImageResource(logoURL) : null;
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    }
  },

  watch: {
    'formData.email'(newVal) {
      this.validateForm()
    },
    'formData.password'(newVal) {
      this.validateForm()
    }
  },

  async created() {
    // console.log('Login component created, checking if already logged in...');
    
    // Check if user is already logged in, redirect if they are
    const isLoggedIn = this.checkIfLoggedIn();
    // console.log('User logged in status:', isLoggedIn);
    
    if (isLoggedIn) {
      // console.log('User is already logged in, redirecting to main page');
      this.redirectToMainPage();
      return;
    }
    
    await this.loadCommunityData();

    this.isLoading = false;
  },

  methods: {
    checkIfLoggedIn() {
      try {
        // Check for access token in cookies using the proper constant
        const cookieToken = Vue.$cookies.get(AccessTokenKey);
        
        // Check localStorage as a fallback
        const localToken = localStorage.getItem(AccessTokenKey);
        
        // Verify token exists in both places for true authentication
        if (cookieToken && localToken) {
          // console.log('Valid tokens found in both cookie and localStorage');
          return true;
        } else if (cookieToken) {
          // console.log('Token found only in cookie');
          // Sync localStorage with cookie for consistency
          localStorage.setItem(AccessTokenKey, cookieToken);
          return true;
        } else if (localToken) {
          // console.log('Token found only in localStorage - this is invalid state, clearing localStorage');
          // Clear the localStorage token since it's not in cookies - user is not truly authenticated
          localStorage.removeItem(AccessTokenKey);
          return false;
        }
        
        // console.log('No valid tokens found');
        return false;
      } catch (error) {
        console.error('Error checking login status:', error);
        return false;
      }
    },
    
    redirectToMainPage() {
      // Construct the URL for the main application page
      const vueAppUrl = process.env.VUE_APP_APP_URL_1 || 'http://localhost:8080';
      let destinationPath = '';
      
      // Only add the community slug path segment if it's a valid string
      if (this.communitySlug && this.communitySlug !== '' && this.communitySlug !== 'undefined' && this.communitySlug !== 'null') {
        // Ensure communitySlug is a string
        const validSlug = typeof this.communitySlug === 'object' ? 
          (this.communitySlug.toString() || '') : 
          this.communitySlug;
          
        // Only add path if the slug is not empty
        if (validSlug.trim() !== '') {
          destinationPath = `/${validSlug}`;
        }
      }

      const destinationURL = this.getValidPath(vueAppUrl + destinationPath);
      // console.log(`Redirecting to main page: ${destinationURL}`);
      window.location.href = destinationURL;
    },

    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },

    async loadCommunityData() {
      try {
        // Ensure proper URL construction with proper domain and path separation
        const baseUrl = process.env.VUE_APP_AUTH_API_URL || 'https://auth-api.nectios.com';
        // Remove any trailing slashes from base URL and ensure proper path construction
        const apiUrl = `${baseUrl}/api/v1/login`;
        
        // Add query parameters properly
        const url = new URL(apiUrl);
        if (this.communitySlug) {
          url.searchParams.append('communitySlug', this.communitySlug);
        }

        const response = await fetch(url.toString());

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.community = data.data;

        // Apply community customizations
        if (this.community?.customization?.theme) {
          const customization = this.community.customization.theme;
          if (customization.primaryColor) {
            document.documentElement.style.setProperty(
              '--primary-color',
              customization.primaryColor
            );
            let hsl = customization.primaryColor;
            hsl = hsl.substring(4, hsl.length - 1);
            hsl = hsl.split(',');
            document.documentElement.style.setProperty('--primary-color-h', hsl[0]);
            document.documentElement.style.setProperty('--primary-color-s', hsl[1]);
            document.documentElement.style.setProperty('--primary-color-l', hsl[2]);
          }
          if (customization.secondaryColor) {
            document.documentElement.style.setProperty(
              '--secondary-color',
              customization.secondaryColor
            );
            let hslSecondary = customization.secondaryColor;
            hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
            hslSecondary = hslSecondary.split(',');
            document.documentElement.style.setProperty('--secondary-color-h', hslSecondary[0]);
            document.documentElement.style.setProperty('--secondary-color-s', hslSecondary[1]);
            document.documentElement.style.setProperty('--secondary-color-l', hslSecondary[2]);
          }
        }

        // Get OAuth apps data using the injected authApiUrl
        const oauth = await this.$store.$service[Service.BackendClient].get(
          'authApps',
          {
            params: { communitySlug: this.communitySlug }
          }
        );
        this.oAuthApps = oauth.data;

        // Get SSO URL if needed
        if (this.communitySlug === 'barcelona-activa') {
          const ssoResponse = await this.$store.$service[Service.BackendClient].get(
            'loginmsal',
            {
              params: { communitySlug: this.communitySlug }
            }
          );
          this.ssoURL = ssoResponse.data.url;
        }

        // Set metadata
        if (this.community.headMeta) {
          const metadata = this.community.headMeta;
          document.getElementsByName('author').content = metadata.name_author;
          document.getElementsByName('description').content = metadata.name_description;
          document.getElementsByName('keywords').content = metadata.name_keywords;
          document.getElementsByName('twitter:title').content = metadata.name_twitter_title;
          document.getElementsByName('twitter:description').content = metadata.name_twitter_description;
          document.getElementById('ogtitle').content = metadata.property_og_title;
          document.getElementById('ogdescription').content = metadata.property_og_description;
        }

        // Set locale
        if (this.community.language) {
          this.$store.dispatch('setLocale', this.community.language);
        }

      } catch (error) {
        console.error('Error loading community data:', error);
        this.error = error.message || this.$t('auth.login.error');
      }
    },

    validateForm() {
      // Basic validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.invalid = !this.formData.email || 
                     !this.formData.password || 
                     !emailRegex.test(this.formData.email) ||
                     this.formData.password.length < 6
    },

    // Add a helper method to ensure valid paths
    getValidPath(path) {
      if (typeof path === 'object') {
        console.error('Invalid path object:', path);
        return '/';
      }
      return path;
    },

    async handleSubmit() {
      const endpoint = this.authApiUrl + 'login';

      try {
        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              ...this.formData,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        // Clone the response before reading it
        const responseClone = response.clone();

        // Add error handling for non-200 responses
        if (!response.ok) {
          let errorMessage;
          try {
            const errorData = await response.json();
            errorMessage = errorData.message;
          } catch (jsonError) {
            // If response is not JSON, use the cloned response to get text
            errorMessage = await responseClone.text();
          }
          throw new Error(errorMessage || this.$t('auth.login.error'));
        }

        const token = await response.json()

        // set the cookie for 7 days
        const date = new Date(
          new Date().setDate(new Date().getDate() + 7)
        )

        // Get parent domain (e.g. ".nectios.com") for sharing across subdomains
        const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost')
        // console.log('Setting cookie with parent domain:', parentDomain);

        // Set cookie for the parent domain to share across subdomains
        Vue.$cookies.set(AccessTokenKey, token, {
          path: '/',
          domain: parentDomain,
          expires: date,
          secure: window.location.protocol === 'https:',
          sameSite: 'Lax'
        });

        // Also set in localStorage as backup
        localStorage.setItem(AccessTokenKey, token);

        // REDIRECT LOGIC
        console.log('this.isIntercontinentalAlliance', this.isIntercontinentalAlliance)
        if(this.isIntercontinentalAlliance){
          // Special case for Intercontinental Alliance customer
          window.location.href = "https://app.intercontinentalalliance.com/intercontinental-alliance"
        } else {
          const vueAppUrl = process.env.VUE_APP_APP_URL_1 || 'http://localhost:8080';
          let destinationPath = '';
          
          if (this.communitySlug) {
            // Ensure communitySlug is a string
            const validSlug = typeof this.communitySlug === 'object' ? 
              (this.communitySlug.toString() || '') : 
              this.communitySlug;
            destinationPath = `/${validSlug}`;
          }

          const destinationURL = this.getValidPath(vueAppUrl + destinationPath);
          window.location.href = destinationURL;
        }
      } catch (error) {
        console.error('Login error:', error);
        this.error = error.message || this.$t('auth.login.error');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-logo {
  width: 120px;
  margin: auto;
  display: block;
}

.brand {
  height: 32px;
}

.input-group-merge {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .form-control-merge {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append {
    display: flex;
    margin-left: -1px;

    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.571rem 1rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      color: #6e6b7b;
      text-align: center;
      white-space: nowrap;
      background-color: #f8f8f8;
      border: 1px solid #d8d6de;
      border-left: 0;
      border-top-right-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
    }
  }

  .toggle-password-icon {
    cursor: pointer;
  }
}
</style>
